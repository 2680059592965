import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import Button from "../../components/shared/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ContactAgentSection = ({ data }) => {
    return (
        <section className="contact-agent-section bg-whisper section-space-ptb">
            <div className="container-full">
                <div className="grid grid-cols-1 lg:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    <div className="left-area">
                        {data?.section_title && (
                            <SectionTitle
                                heading={data?.section_title?.heading}
                                {...data?.section_title}
                            />
                        )}
                        {data?.agent &&
                            data?.agent.map((agent) => (
                                <div
                                    key={agent?.id}
                                    className="agent-item mt-10 mb-10 lg:mb-0"
                                >
                                    {agent?.image && (
                                        <div className="agent-image w-32 h-32 mb-6">
                                            <GatsbyImage
                                                className="rounded-full"
                                                image={getImage(
                                                    agent?.image?.src
                                                )}
                                                alt={
                                                    agent?.image?.alt ||
                                                    "Agent Image"
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className="agent-details">
                                        {agent?.name && (
                                            <h3 className="agent-name mb-0">
                                                {agent?.name}
                                            </h3>
                                        )}
                                        {agent?.designation && (
                                            <span className="text-[13px] mb-6 block">
                                                {agent?.designation}
                                            </span>
                                        )}
                                        <ul className="agent-info mb-7">
                                            {agent?.phone && (
                                                <li className="text-black">
                                                    Phone:{" "}
                                                    <a
                                                        className="text-[#515151] hover:text-primary"
                                                        href={`tel:${agent?.phone}`}
                                                    >
                                                        {agent?.phone}
                                                    </a>
                                                </li>
                                            )}
                                            {agent?.email && (
                                                <li className="text-black">
                                                    Email:{" "}
                                                    <a
                                                        className="text-[#515151] hover:text-primary"
                                                        href={`mailto:${agent?.email}`}
                                                    >
                                                        {agent?.email}
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                        <div className="more-details-button">
                                            <Link
                                                to={`/agent/${agent.slug}`}
                                                className="uppercase underline text-sm hover:text-primary"
                                            >
                                                SEE PROFILE
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="content-block">
                        <div className="content-block-inner mb-16">
                            {data?.headings && (
                                <h2 className="content-block-title mb-10">
                                    {data?.headings[0]?.content}
                                </h2>
                            )}
                            <ul className="feature-list grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                {data?.items?.map((item) => (
                                    <li
                                        key={item.id}
                                        className="before:text-center before:text-white before:rounded-full before:left-0 before:top-1 before:leading-6 before:w-6 before:h-6 before:content-['\f12d'] before:bg-primary before:absolute before:font-flaticon before:text-xs last:mb-0 mb-[18px] text-lg relative block pl-10 text-black"
                                    >
                                        {item.title}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="agent-contact-form ajax-form">
                            <form action="#">
                                <div className="grid grid-cols-2 gap-x-10 gap-y-5">
                                    <div className="single-input">
                                        <input
                                            className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                            type="text"
                                            name="name"
                                            placeholder="Your Name"
                                        />
                                    </div>
                                    <div className="single-input">
                                        <input
                                            className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                            type="text"
                                            name="phone"
                                            placeholder="Your Phone"
                                        />
                                    </div>
                                    <div className="single-input col-span-2">
                                        <input
                                            className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                            type="email"
                                            name="email"
                                            placeholder="Your Email Address *"
                                        />
                                    </div>
                                    <div className="single-input col-span-2">
                                        <textarea
                                            className="form-control h-44 w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            color="light"
                                            type="submit"
                                            className="px-16"
                                        >
                                            submit
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContactAgentSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        agent: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default ContactAgentSection;
