import React from "react";
import PropTypes from "prop-types";

const ContactInfo = ({ contactInfo }) => {
    return (
        <ul>
            {contactInfo?.map((item) => (
                <li
                    className="mb-4 last:mb-0 block"
                    key={item.id}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                />
            ))}
        </ul>
    );
};

ContactInfo.propTypes = {
    contactInfo: PropTypes.array,
};

export default ContactInfo;
