import React, { useState } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContactUsInfo = ({ data }) => {
    // eslint-disable-next-line react/prop-types
    const [activeId, setActiveId] = useState(data.items[0].id);
    return (
        <section className="contact-us-info-area section-space-pb">
            <div className="container-full">
                <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="md:col-span-5">
                        {data?.contactInfo.map((item) => (
                            <div
                                key={item.id}
                                className="single-info mb-12 last:mb-0"
                            >
                                <h2 className="mb-[25px]">{item.title}</h2>
                                <ul>
                                    {item.text && (
                                        <li className="mb-[14px] last:mb-0">
                                            {item.text}
                                        </li>
                                    )}
                                    {item.number && (
                                        <li className="mb-[14px] last:mb-0">
                                            <a href={`tel:${item.number}`}>
                                                {item.number}
                                            </a>
                                        </li>
                                    )}
                                    {item.email && (
                                        <li className="mb-[14px] last:mb-0">
                                            <a href={`mailto:${item.email}`}>
                                                {item.email}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="md:col-span-7">
                        <div className="map-location-layer relative">
                            <GatsbyImage
                                className="w-full cover object-cover"
                                image={getImage(data?.images[0].src)}
                                alt={data?.images[0].alt}
                            />
                            {data?.items?.map((item) => (
                                <div
                                    key={item.id}
                                    className={`absolute w-3 h-3 cursor-pointer rounded-full bg-primary shadow-[0_0_0_10px_rgb(14_164_120_/_10%)] ${
                                        activeId === item.id ? "active" : ""
                                    } ${item.id}`}
                                    onClick={() => setActiveId(item.id)}
                                    role="presentation"
                                >
                                    <span
                                        className="title opacity-0 before:absolute before:bottom-[-8px] before:left-1/2 
                                        before:w-4 before:h-4 before:content-[''] before:-z-[1] before:-translate-x-1/2
                                        before:rotate-45 before:bg-white
                                     shadow-[0_10px_40px_0_rgb(0_0_0_/_15%)] text-black rounded bg-white p-[5px_15px] left-1/2 -translate-x-1/2 whitespace-nowrap text-sm font-semibold top-[-70px] absolute "
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                            <div className="text-center absolute bottom-0 text-[#064DF0] w-full">
                                <a
                                    className="google-map-link"
                                    href={data?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fi fi-map-1 mr-2"></i>OPEN
                                    GOOGLE MAP
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContactUsInfo.propTypes = {
    data: PropTypes.shape({
        contactInfo: PropTypes.array,
        link: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default ContactUsInfo;
