import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
const SectionTitle = ({
    subHeading,
    heading,
    description,
    align,
    color,
    showDescription,
}) => {
    const titleAlign = {
        left: `ml-0`,
        center: `mx-auto text-center`,
        right: `mr-0`,
    };
    const titleColor = {
        primary: `text-primary`,
        white: `text-white`,
        black: `text-black`,
    };
    const title = {
        showDescription: showDescription || false,
        align: align || "center",
        color: color || "black",
    };
    const titelColorClass = cn(titleColor[title.color]);
    const titleClasses = cn(
        `section-title mx-auto`,
        titleAlign[title["align"]]
    );
    return (
        <div className={titleClasses}>
            {subHeading && (
                <h6 className="font-normal text-primary uppercase text-sm">
                    {subHeading}
                </h6>
            )}
            {heading && (
                <h2
                    className={`${titelColorClass} mt-5 lg:mt-10 mb-10 md:mb-14 xl:mb-20 font-normal font-playfair text-[32px] md:text-[40px] lg:text-[50px] xl:text-[72px] uppercases leading-none`}
                >
                    {heading}
                </h2>
            )}
            {title.showDescription && (
                <p className="max-w-xl mt-2 leading-7 text-18base">
                    {description}
                </p>
            )}
        </div>
    );
};
SectionTitle.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    align: PropTypes.oneOf(["left", "right", "center"]),
    color: PropTypes.oneOf(["white", "primary", "black"]),
    showDescription: PropTypes.bool,
};
export default SectionTitle;
