import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const MainMenuLightBG = ({ allmenuData }) => {
    const menuarr = allmenuData;
    return (
        <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
            {menuarr?.map((menu) => {
                const submenu = menu.submenu;
                return (
                    <li
                        key={`menu-${menu.id}`}
                        className={`${
                            !!submenu ? "has-submenu" : ""
                        } menu-item text-black group relative`}
                    >
                        <Link
                            activeClassName="active"
                            to={menu.link}
                            className="font-semibold text-sm uppercase p-[45px_0] block"
                        >
                            {menu.text}
                        </Link>
                        {!!submenu && (
                            <ul
                                className="submenu-nav border-t-2 border-primary absolute left-0 z-50 bg-black mt-14 
                            opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:mt-0 transition-all py-4 
                            duration-500 min-w-200 w-64"
                            >
                                {submenu.map((submenu, i) => {
                                    return (
                                        <li
                                            key={`submenu${i}`}
                                            className="last:border-b-0 border-b border-white/30"
                                        >
                                            <Link
                                                to={submenu.link}
                                                className="menu-sub-item px-4 py-3 text-sm font-medium text-white block hover:text-primary"
                                            >
                                                {submenu.text}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
MainMenuLightBG.propTypes = {
    allmenuData: PropTypes.array,
};
export default MainMenuLightBG;
