import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import PageBreadcrumb from "../components/pagebreadcrumb";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import ContactAgentSection from "../container/contact-agent";
import ContactUsInfo from "../container/contact-info-area";

const ContactUsPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Contact Us page" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Contact"
            />
            <ContactUsInfo
                data={{
                    ...content?.["map-location-list-section"],
                    ...globalContent?.["contactInfo"],
                }}
            />
            <ContactAgentSection data={content?.["contact-agent-section"]} />
            <Footer
                data={{
                    ...globalContent?.["footer"],
                }}
            />
        </Layout>
    );
};

ContactUsPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query ContactUsPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
                contactInfo {
                    id
                    email
                    number
                    title
                    text
                }
            }
        }
        page(title: { eq: "contactUsPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
            }
        }
    }
`;
export default ContactUsPage;
